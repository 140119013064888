@import 'src/styles/variables/colors';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, $primary-color 0%, #908ef2 100%);
}

.container {
  max-width: 500px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px;
  transform: translateX(-10%);
}

.banner {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
}

.calendarIcon {
  position: absolute;
  opacity: 0;
  width: 75px;
  animation-duration: 15s;
  animation-name: move-right;
  animation-iteration-count: infinite;
}

.messageIcon {
  opacity: 0;
  position: absolute;
  animation-duration: 15s;
  animation-name: move-right-with-scale;
  animation-iteration-count: infinite;
  animation-delay: 5s;
}

.title {
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  padding-bottom: 20px;
  color: #fff;
}

.message {
  opacity: 0;
  animation-duration: 15s;
  animation-name: show;
  animation-iteration-count: infinite;
  animation-delay: 10s;
  position: relative;
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  background: #e5e5ea;
  max-width: 270px;
  font-size: 13px;
  line-height: 17px;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #e5e5ea;
    border-bottom-right-radius: 15px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: #7080e4;
    border-bottom-right-radius: 10px;
  }
}

@keyframes move-right {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }

  12%,
  24% {
    transform: translateX(0);
    opacity: 1;
  }

  36%,
  100% {
    transform: translateX(200px);
    opacity: 0;
  }
}

@keyframes move-right-with-scale {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }

  9% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }

  18% {
    transform: scale(1.2);
  }

  27% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }

  36%,
  100% {
    opacity: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  9%,
  27% {
    opacity: 1;
  }

  36%,
  100% {
    opacity: 0;
  }
}
